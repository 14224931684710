@charset "UTF-8";
button[disabled],
html input[disabled] {
  cursor: not-allowed; }

html,
body {
  height: 100%; }

body {
  overflow-x: hidden;
  overflow-y: auto; }

/* Layout */
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto; }
  .wrapper:before, .wrapper:after {
    display: table;
    content: " "; }
  .wrapper:after {
    clear: both; }

/*
 * Content Wrapper - contains the main content
 */
.content-wrapper,
.main-footer {
  transition: transform 3s ease-in-out, margin 3s ease-in-out;
  margin-left: 230px;
  z-index: 820; }
  .layout-top-nav .content-wrapper, .layout-top-nav
  .main-footer {
    margin-left: 0; }
  @media (max-width: 767px) {
    .content-wrapper,
    .main-footer {
      margin-left: 0; } }
  @media (min-width: 768px) {
    .sidebar-collapse .content-wrapper, .sidebar-collapse
    .main-footer {
      margin-left: 0; } }
  @media (max-width: 767px) {
    .sidebar-open .content-wrapper, .sidebar-open
    .main-footer {
      transform: translate(230px, 0); } }

.content-wrapper {
  min-height: 100vh;
  z-index: 800; }

/* Fixed layout */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed; }

.fixed .main-header {
  top: 0;
  right: 0;
  left: 0; }

.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px; }

.fixed .wrapper {
  overflow: hidden; }

.hold-transition .content-wrapper,
.hold-transition .right-side,
.hold-transition .main-footer,
.hold-transition .main-sidebar,
.hold-transition .left-side,
.hold-transition .main-header .navbar,
.hold-transition .main-header .logo,
.hold-transition .menu-open .fa-angle-left {
  /* Fix for IE */
  transition: none; }

/* Content */
.content {
  max-width: 1440px;
  min-height: 250px;
  padding: 15px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .content:before, .content:after {
    display: table;
    content: " "; }
  .content:after {
    clear: both; }

/*
 * Component: Main Header
 * ----------------------
 */
.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030; }
  .main-header .navbar {
    transition: margin-left 3s ease-in-out;
    margin-bottom: 0;
    margin-left: 230px;
    border: none;
    min-height: 50px;
    border-radius: 0; }
    .layout-top-nav .main-header .navbar {
      margin-left: 0; }
  .main-header #navbar-search-input.form-control {
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent; }
    .main-header #navbar-search-input.form-control:focus, .main-header #navbar-search-input.form-control:active {
      border-color: rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.9); }
    .main-header #navbar-search-input.form-control::-moz-placeholder {
      color: #ccc;
      opacity: 1; }
    .main-header #navbar-search-input.form-control:-ms-input-placeholder {
      color: #ccc; }
    .main-header #navbar-search-input.form-control::-webkit-input-placeholder {
      color: #ccc; }
  .main-header .navbar-custom-menu,
  .main-header .navbar-right {
    float: right; }
  @media (max-width: 767px) {
    .main-header .navbar-right {
      float: none; }
      .navbar-collapse .main-header .navbar-right {
        margin: 7.5px -15px; }
      .main-header .navbar-right > li {
        color: inherit;
        border: 0; } }
  .main-header .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px 15px;
    font-family: fontAwesome; }
    .main-header .sidebar-toggle:before {
      content: "\f0c9"; }
    .main-header .sidebar-toggle:hover {
      color: #fff; }
    .main-header .sidebar-toggle:focus, .main-header .sidebar-toggle:active {
      background: transparent; }
    .main-header .sidebar-toggle.fa5 {
      font-family: "Font Awesome\ 5 Free"; }
      .main-header .sidebar-toggle.fa5:before {
        content: "\f0c9";
        font-weight: 900; }
  .main-header .navbar .nav > li.user > a > .fa,
  .main-header .navbar .nav > li.user > a > .glyphicon,
  .main-header .navbar .nav > li.user > a > .ion {
    margin-right: 5px; }
  .main-header .navbar .nav > li > a > .label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: .9; }
  .main-header .logo {
    transition: width 3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 230px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden; }
    .main-header .logo img {
      -o-object-fit: contain;
         object-fit: contain;
      margin: 0 auto; }
    .main-header .logo .logo-lg {
      display: block;
      margin-top: 10px; }
      .main-header .logo .logo-lg img {
        max-width: 200px;
        max-height: 50px; }
      .main-header .logo .logo-lg .brandlogo-image {
        margin-top: 8px;
        margin-right: 10px;
        margin-left: -5px; }

.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: 15px 15px; }

@media (max-width: 991px) {
  .navbar-custom-menu .navbar-nav > li {
    float: left;
    position: static; }
    .navbar-custom-menu .navbar-nav > li > .dropdown-menu {
      position: absolute;
      right: 5%;
      left: auto;
      border: 1px solid #ddd;
      background: #fff; }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left; }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px; } }

@media (max-width: 767px) {
  .main-header {
    position: relative; }
    .main-header .logo,
    .main-header .navbar {
      width: 100%;
      float: none; }
    .main-header .navbar {
      margin: 0; }
    .main-header .navbar-custom-menu {
      float: right; } }

@media (max-width: 991px) {
  .navbar-collapse.pull-left {
    float: none !important; }
    .navbar-collapse.pull-left + .navbar-custom-menu {
      display: block;
      position: absolute;
      top: 0;
      right: 40px; } }

.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto; }

@media all and (display-mode: standalone) {
  .navbar-nav .install {
    display: none; } }

.navbar-nav .install {
  background-color: #c3d21b; }
  .navbar-nav .install a {
    color: black !important; }
    .navbar-nav .install a:hover {
      background-color: #9fab15 !important; }

.navbar-nav > .account-menu > .dropdown-menu,
.navbar-nav > .lang-menu > .dropdown-menu {
  width: 250px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%; }
  .navbar-nav > .account-menu > .dropdown-menu > li,
  .navbar-nav > .lang-menu > .dropdown-menu > li {
    position: relative; }
    .navbar-nav > .account-menu > .dropdown-menu > li .menu,
    .navbar-nav > .lang-menu > .dropdown-menu > li .menu {
      max-height: 250px;
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-x: hidden; }
      .navbar-nav > .account-menu > .dropdown-menu > li .menu > li > a,
      .navbar-nav > .lang-menu > .dropdown-menu > li .menu > li > a {
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
        color: #444444;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px; }
        .navbar-nav > .account-menu > .dropdown-menu > li .menu > li > a:hover,
        .navbar-nav > .lang-menu > .dropdown-menu > li .menu > li > a:hover {
          background: #f4f4f4;
          text-decoration: none; }

/*
 * Component: Sidebar
 * ------------------
 */
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  transition: transform 3s ease-in-out, width 3s ease-in-out; }
  @media (max-width: 767px) {
    .main-sidebar {
      padding-top: 100px; } }
  @media (max-width: 767px) {
    .main-sidebar {
      padding-top: 50px;
      transform: translate(-230px, 0); } }
  @media (min-width: 768px) {
    .sidebar-collapse .main-sidebar {
      transform: translate(-230px, 0); } }
  @media (max-width: 767px) {
    .sidebar-open .main-sidebar {
      transform: translate(0, 0); } }

.sidebar {
  padding-bottom: 10px; }

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0; }
  .sidebar-menu > li {
    position: relative;
    margin: 0;
    padding: 0; }
    .sidebar-menu > li > a {
      padding: 12px 5px 12px 15px;
      display: block; }
      .sidebar-menu > li > a > .fa,
      .sidebar-menu > li > a > .glyphicon,
      .sidebar-menu > li > a > .ion {
        width: 20px; }
    .sidebar-menu > li .label,
    .sidebar-menu > li .badge {
      margin-right: 5px; }
    .sidebar-menu > li .badge {
      margin-top: 3px; }
  .sidebar-menu li.header {
    overflow: hidden;
    text-overflow: clip;
    padding: 10px 25px 10px 15px;
    font-size: 12px; }
  .sidebar-menu li.patient-therapy-request {
    background: #f7f7f7;
    margin-top: 10px; }
    .sidebar-menu li.patient-therapy-request:hover {
      background: white;
      color: #066278; }
      .sidebar-menu li.patient-therapy-request:hover > a {
        color: #066278 !important; }
    .sidebar-menu li.patient-therapy-request > a {
      color: #1c91ad; }
  .sidebar-menu li > a {
    position: relative; }
    .sidebar-menu li > a > .pull-right-container {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7px; }
    .sidebar-menu li > a > .fa-angle-left,
    .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 10px;
      transition: transform 0.5s ease; }
    .sidebar-menu li > a > .fa-angle-left {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -8px; }
  .sidebar-menu .menu-open > a > .fa-angle-left,
  .sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-left {
    transform: rotate(-90deg); }
  .sidebar-menu .active > .treeview-menu {
    display: block; }
  .sidebar-menu:hover {
    overflow: visible; }

/*
 * Component: Sidebar Mini
 */
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840; }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    transform: translate(0, 0);
    width: 50px !important;
    z-index: 850; }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
      margin-right: 0; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
      border-top-right-radius: 4px; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
      border-bottom-right-radius: 4px; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom-right-radius: 4px; }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0); }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px; }
    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
      display: none; }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px; } }

@media (min-width: 768px) {
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    display: block !important;
    position: absolute;
    width: 180px;
    left: 50px; }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > span {
    top: 0;
    margin-left: -3px;
    padding: 12px 5px 12px 20px;
    background-color: inherit; }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
    position: relative !important;
    float: right;
    width: auto !important;
    left: 180px !important;
    top: -22px !important;
    z-index: 900; }
    .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type) {
      display: none; }
  .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    top: 44px;
    margin-left: 0; } }

.sidebar-expanded-on-hover .main-footer,
.sidebar-expanded-on-hover .content-wrapper {
  margin-left: 50px; }

.sidebar-expanded-on-hover .main-sidebar {
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.125); }

.sidebar-menu,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden; }

/* form control override */
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none; }

/* custom select */
select:not([multiple]) {
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=); }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  border-color: #1c91ad;
  outline: none; }

/* custom radio & checkboxes **/
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-top: 2px; }

input[type=checkbox],
input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border: 1px solid #D1D5DB;
  color: #1c91ad;
  display: inline-block;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  vertical-align: middle;
  width: 16px;
  height: 16px; }
  input[type=checkbox]:disabled,
  input[type=radio]:disabled {
    background-color: #f5f5f5; }

input[type="checkbox"] {
  border-radius: 4px; }

input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
  background-size: 10px 12px;
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  border-color: transparent; }

input[type="radio"] {
  border-radius: 100%; }

input[type=radio]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  background-size: 1em 1em;
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  border-color: transparent; }

.radio-rating {
  padding: 0 5px;
  text-align: center; }

/* 1-line textarea same height as text inputs */
textarea[rows="1"].form-control {
  height: 34px; }

/* help text */
.help-block {
  font-size: 0.9em; }

/* enforce label color */
.control-label {
  color: inherit !important; }

.progress,
.progress > .progress-bar {
  box-shadow: none; }

/* size variation */
.progress.sm,
.progress-sm {
  height: 10px; }

.progress.xs,
.progress-xs {
  height: 7px; }

.progress.xxs,
.progress-xxs {
  height: 3px; }

/* Remove margins from progress bars when put in a table */
.table .progress {
  margin: 0; }

.box {
  position: relative;
  background-color: #fff;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }
  .box.box-padding {
    padding: 1.5rem; }
  .box:focus {
    outline: none;
    box-shadow: none; }
  .box.box-default-l {
    border-left: 3px solid #d2d6de; }
  .box.box-default-t {
    border-top: 3px solid #d2d6de; }
  .box.box-primary-t {
    border-top: 3px solid #1c91ad; }
  .box.box-primary-l {
    border-left: 3px solid #1c91ad; }
  .box.box-info-t {
    border-top: 3px solid #00c0ef; }
  .box.box-info-l {
    border-left: 3px solid #00c0ef; }
  .box.box-danger-t {
    border-top: 3px solid #d9534f; }
  .box.box-danger-l {
    border-left: 3px solid #d9534f; }
  .box.box-warning-t {
    border-top: 3px solid #f39c12; }
  .box.box-warning-l {
    border-left: 3px solid #f39c12; }
  .box.box-success-t {
    border-top: 3px solid #45a972; }
  .box.box-success-l {
    border-left: 3px solid #45a972; }
  .box > .table,
  .box > .table-responsive,
  .box > .table-responsive > table {
    margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .box > .table,
      .box > .table-responsive,
      .box > .table-responsive > table {
        border: none;
        margin-bottom: 0; } }

.box > .overlay,
.box > .loading-img,
.overlay-wrapper > .overlay,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px; }
  .box .overlay > .fa,
  .overlay-wrapper .overlay > .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px; }

.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5); }

.box-header:before, .box-header:after,
.box-body:before,
.box-body:after,
.box-footer:before,
.box-footer:after {
  display: table;
  content: " "; }

.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both; }

.box-header {
  border-bottom: 1px solid #f5f5f5;
  color: #444;
  display: block;
  padding: 1.5rem;
  position: relative; }
  .box-header > .fa,
  .box-header > .glyphicon,
  .box-header > .ion,
  .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1; }
  .box-header > .fa,
  .box-header > .glyphicon,
  .box-header > .ion {
    margin-right: 5px; }

.box-body {
  padding: 1.5rem; }
  .box-body > .table {
    margin-bottom: 0; }
  .box-body .fc {
    margin-top: 5px; }
  .box-body .full-width-chart {
    margin: -19px; }
  .box-body.no-padding .full-width-chart {
    margin: -9px; }

.box-footer {
  border-top: 1px solid #f4f4f4;
  padding: 1.5rem;
  background-color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; }
  .box-footer .info {
    float: left; }
  .box-footer .pagination {
    margin: 0;
    float: right; }

.modal .panel-body {
  color: #444; }

.box .overlay > .fas,
.overlay-wrapper .overlay > .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px; }

/*
 * Component: Info Box
 * -------------------
 */
.info-box {
  border-radius: 4px;
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px; }
  .info-box small {
    font-size: 14px; }

.info-box-icon {
  display: block;
  float: left;
  height: 90px;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0, 0, 0, 0.2); }
  .info-box-icon > img {
    max-width: 100%; }

.info-box-content {
  padding: 5px 10px;
  margin-left: 90px; }

.info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px; }

.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.info-box-text {
  text-transform: uppercase; }

.info-box-more {
  display: block; }

.progress-description {
  margin: 0; }

/*
 * Component: Small Box
 * --------------------
 */
.small-box {
  border-radius: 4px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); }
  .small-box > .inner {
    padding: 10px; }
  .small-box > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none; }
  .small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0; }
  .small-box p {
    font-size: 15px; }
    .small-box p > small {
      display: block;
      color: #f9f9f9;
      font-size: 13px;
      margin-top: 5px; }
  .small-box h3, .small-box p {
    z-index: 5; }
  .small-box .icon {
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15); }

@media (max-width: 767px) {
  .small-box {
    text-align: center; }
    .small-box .icon {
      display: none; }
    .small-box p {
      font-size: 12px; } }

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none; }

.btn:active, .btn.active {
  box-shadow: none; }

.btn.select {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 34px; }

.btn-link:disabled {
  color: #777777;
  cursor: not-allowed; }

.btn-excel {
  background-color: #45a972;
  color: #fff; }
  .btn-excel:hover, .btn-excel:focus, .btn-excel.focus {
    background-color: #36855a;
    color: #fff; }

button[disabled] {
  opacity: .7; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.alert a {
  text-decoration: underline; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  border-radius: 0;
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  z-index: 10002;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
  color: #444;
  background: #f7f7f7; }

/* PAGINATION */
.pagination {
  margin: 0; }

.breadcrumb {
  margin-bottom: 0; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  /* Supprime la bordure supérieure si le tableau a une seule ligne */ }
  .table tbody > tr > td {
    vertical-align: middle; }
  .table tr:only-child {
    border-top: none !important; }
  .table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td {
    border-top: 0; }
  .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td {
    border-top: 0; }
  .table > thead:first-child + thead > tr:first-child > th, .table > thead:first-child + thead > tr:first-child > td {
    border-top: 0; }

.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0; }

/* .text-center in tables */
table.text-center, table.text-center td, table.text-center th {
  text-align: center; }

.table.align th {
  text-align: left; }

.table.align td {
  text-align: right; }

@media screen and (max-width: 767px) {
  .table-responsive {
    border: none; } }

tr:focus {
  outline: none !important; }

tr.clickable {
  cursor: pointer; }

th {
  white-space: nowrap; }

.jh-table > tbody > tr:hover {
  background: #eeeeee !important; }

tr.active {
  border-left: 3px solid black; }

tr.clickable {
  cursor: pointer; }
  tr.clickable:hover {
    background: #eeeeee !important; }

.treeview-menu {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .7s linear;
  list-style: none; }
  .treeview-menu > li {
    margin: 0; }
    .treeview-menu > li > a {
      padding: 5px 5px 5px 15px;
      display: block;
      font-size: 14px; }
      .treeview-menu > li > a > .fa,
      .treeview-menu > li > a > .glyphicon,
      .treeview-menu > li > a > .ion {
        width: 20px; }
      .treeview-menu > li > a > .pull-right-container > .fa-angle-left,
      .treeview-menu > li > a > .pull-right-container > .fa-angle-down,
      .treeview-menu > li > a > .fa-angle-left,
      .treeview-menu > li > a > .fa-angle-down {
        width: auto; }

.treeview > ul.treeview-menu {
  overflow: hidden;
  height: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.treeview.menu-open > ul.treeview-menu {
  overflow: visible;
  height: auto;
  position: relative;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear; }

.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.tooltip-inner {
  min-width: 100px;
  max-width: 100%;
  background-color: white;
  color: black;
  border: 2px solid gray; }

.visit-step-selection .ui-select-match-item {
  width: 100%;
  height: 3rem; }

/* ui-select overrides */
.btn-default-focus {
  outline: none;
  border-color: #1c91ad;
  box-shadow: none; }

.ui-select-bootstrap > .ui-select-choices {
  margin-top: 3px;
  border-radius: 8px;
  border: 1px solid #d1d5db; }

.ui-select-choices::-webkit-scrollbar-thumb {
  background: #d9534f;
  border-radius: 8px; }

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background-color: #1c91ad; }

.ui-select-bootstrap > .ui-select-no-choice {
  margin-top: 3px;
  border: none; }

.ui-select-highlight {
  text-decoration: underline; }

[class^="bg-"].disabled {
  opacity: .65; }

.bg-score-0 {
  background-color: #eeeeee !important;
  color: #888; }

.bg-score-1 {
  background-color: #fef3c7 !important;
  color: #555; }

.bg-score-2 {
  background-color: #fdba74 !important;
  color: black; }

.bg-score-3 {
  background-color: #e53e3e !important;
  color: white; }

.bg-score-4 {
  background-color: #000000 !important;
  color: white; }

.bg-stripped {
  background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(100, 100, 100, 0.1) 10px, rgba(200, 200, 200, 0.1) 20px); }
  .bg-stripped > * {
    opacity: .5; }

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)); }

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); }

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)); }

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)); }

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)); }

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)); }

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)); }

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)); }

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)); }

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)); }

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)); }

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)); }

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)); }

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)); }

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)); }

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)); }

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)); }

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)); }

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)); }

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)); }

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)); }

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)); }

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)); }

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)); }

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)); }

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)); }

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)); }

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)); }

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)); }

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)); }

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)); }

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)); }

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)); }

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)); }

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)); }

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)); }

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)); }

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)); }

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)); }

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)); }

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)); }

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)); }

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)); }

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)); }

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)); }

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)); }

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)); }

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)); }

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)); }

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)); }

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)); }

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)); }

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)); }

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)); }

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)); }

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)); }

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)); }

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)); }

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)); }

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)); }

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)); }

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)); }

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)); }

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)); }

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)); }

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)); }

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)); }

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)); }

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)); }

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)); }

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)); }

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)); }

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)); }

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)); }

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)); }

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)); }

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)); }

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)); }

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)); }

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)); }

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)); }

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)); }

.bg-orange-300 {
  background-color: #fdba74; }

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 145, 173, var(--tw-bg-opacity)); }

.bg-primary-o30 {
  --tw-bg-opacity: 0.3;
  background-color: rgba(28, 145, 173, var(--tw-bg-opacity)); }

.hover\:bg-primary-o50:hover {
  background-color: rgba(28, 145, 173, 0.5); }

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 145, 173, var(--tw-bg-opacity)); }

.hover\:bg-default:hover {
  background-color: #f5f5f5; }

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)); }

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); }

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)); }

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)); }

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)); }

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)); }

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)); }

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)); }

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)); }

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)); }

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)); }

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)); }

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)); }

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)); }

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)); }

.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)); }

.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)); }

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)); }

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)); }

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)); }

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)); }

.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)); }

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)); }

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)); }

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)); }

.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)); }

.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)); }

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)); }

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)); }

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)); }

.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)); }

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)); }

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)); }

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)); }

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)); }

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)); }

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)); }

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)); }

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)); }

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)); }

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)); }

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)); }

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)); }

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)); }

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)); }

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)); }

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)); }

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)); }

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)); }

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)); }

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)); }

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)); }

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)); }

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)); }

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)); }

.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)); }

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)); }

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)); }

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)); }

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)); }

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)); }

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)); }

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)); }

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)); }

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)); }

.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)); }

.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)); }

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)); }

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)); }

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)); }

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)); }

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)); }

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)); }

.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)); }

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)); }

.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)); }

.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)); }

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)); }

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)); }

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)); }

.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)); }

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)); }

.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active {
  color: #fff !important; }

.bg-gray {
  color: #000;
  background-color: #ecf0f5 !important; }

.bg-gray-light {
  background-color: #f7f7f7; }

.bg-black {
  background-color: #111 !important; }

.bg-red {
  background-color: #d9534f !important; }

.bg-yellow {
  background-color: #f39c12 !important; }

.bg-aqua {
  background-color: #00c0ef !important; }

.bg-blue {
  background-color: #1c91ad !important; }

.bg-green {
  background-color: #45a972 !important; }

.bg-navy {
  background-color: #001F3F !important; }

.bg-teal {
  background-color: #39CCCC !important; }

.bg-olive {
  background-color: #3D9970 !important; }

.bg-lime {
  background-color: #01FF70 !important; }

.bg-orange {
  background-color: #FF851B !important; }

.bg-fuchsia {
  background-color: #F012BE !important; }

.bg-purple {
  background-color: #605ca8 !important; }

.bg-maroon {
  background-color: #D81B60 !important; }

.bg-gray-active {
  color: #000;
  background-color: #cbd6e3 !important; }

.bg-black-active {
  background-color: black !important; }

.bg-red-active {
  background-color: #d43a36 !important; }

.bg-yellow-active {
  background-color: #db8b0b !important; }

.bg-aqua-active {
  background-color: #00a7d0 !important; }

.bg-blue-active {
  background-color: #156c81 !important; }

.bg-green-active {
  background-color: #3e9766 !important; }

.bg-navy-active {
  background-color: #001a35 !important; }

.bg-teal-active {
  background-color: #30bbbb !important; }

.bg-olive-active {
  background-color: #368763 !important; }

.bg-lime-active {
  background-color: #00e765 !important; }

.bg-orange-active {
  background-color: #ff7702 !important; }

.bg-fuchsia-active {
  background-color: #db0ead !important; }

.bg-purple-active {
  background-color: #555299 !important; }

.bg-maroon-active {
  background-color: #ca195a !important; }

.bg-teal-gradient {
  background-image: linear-gradient(to bottom, #39CCCC 0%, #7adddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF39CCCC', endColorstr='#FF7ADDDD', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-blue-gradient {
  background-image: linear-gradient(to bottom, #1c91ad 0%, #21abcc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF1C91AD', endColorstr='#FF21ABCC', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-aqua-gradient {
  background-image: linear-gradient(to bottom, #00c0ef 0%, #14d1ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF00C0EF', endColorstr='#FF14D1FF', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-yellow-gradient {
  background-image: linear-gradient(to bottom, #f39c12 0%, #f7bc60 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF39C12', endColorstr='#FFF7BC60', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-purple-gradient {
  background-image: linear-gradient(to bottom, #605ca8 0%, #9491c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF605CA8', endColorstr='#FF9491C4', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-green-gradient {
  background-image: linear-gradient(to bottom, #45a972 0%, #57ba84 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF45A972', endColorstr='#FF57BA84', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-red-gradient {
  background-image: linear-gradient(to bottom, #d9534f 0%, #e27c79 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD9534F', endColorstr='#FFE27C79', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-black-gradient {
  background-image: linear-gradient(to bottom, #111 0%, #2b2b2b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF111111', endColorstr='#FF2B2B2B', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-maroon-gradient {
  background-image: linear-gradient(to bottom, #D81B60 0%, #e73f7c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD81B60', endColorstr='#FFE73F7C', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.bg-trial {
  background-color: #777777;
  color: #fff; }

.bg-FEGALA {
  background-image: linear-gradient(to bottom, #f967f8 0%, #ab86f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF967F8', endColorstr='#FFAB86F3', GradientType=0);
  background-repeat: repeat-x;
  color: #fff; }

.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 100%;
  text-align: center;
  padding: 50px 10px;
  cursor: pointer; }

.drop-box-xs {
  padding: 20px 10px; }

.absolute,
.p-absolute {
  position: absolute; }

.relative {
  position: relative; }

.z-1000 {
  z-index: 1000; }

.block {
  display: block; }

.hidden {
  display: none; }

.hide {
  display: none !important; }

.readonly {
  background-color: #eee;
  opacity: 1; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.float-none {
  float: none !important; }

.overflow-wrap {
  overflow-wrap: break-word; }

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0; }

.chart {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .chart svg,
  .chart canvas {
    width: 100% !important; }

.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  color: #fff; }

.glass-effect {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.3); }

.rounded-none {
  border-radius: 0; }

.rounded {
  border-radius: 0.25rem;
  /* 4px */ }

.rounded-lg {
  border-radius: 0.5rem; }

.rounded-xl {
  border-radius: 0.75rem; }

.rounded-2xl {
  border-radius: 1rem; }

.rounded-3xl {
  border-radius: 1.5rem; }

.rounded-full {
  border-radius: 9999px; }

.rounded-50 {
  border-radius: 50%; }

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem; }

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem; }

.rounded-full {
  border-radius: 9999px; }

.cursor-default {
  cursor: default; }

.cursor-pointer {
  cursor: pointer; }

.cursor-not-allowed {
  cursor: not-allowed; }

.object-cover {
  -o-object-fit: cover;
     object-fit: cover; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.shadow-box {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.scrollbar-y {
  overflow-y: auto; }

/* Style de la barre de défilement */
.scrollbar::-webkit-scrollbar {
  width: 16px;
  /* Largeur de la barre de défilement */
  background-color: white;
  /* Couleur d'arrière-plan de la barre  */
  border-radius: 1rem; }

/* Style du bouton de défilement */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #888888;
  /* Couleur de fond du bouton */
  border-radius: 10px;
  /* Rayon de la bordure du bouton */
  border: 6px solid white;
  /* Couleur et taille de la bordure du bouton */ }

/* Style du bouton de défilement au survol */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8; }

.opacity-30 {
  opacity: 0.3; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.opacity-70 {
  opacity: 0.7; }

.split-layout {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1024px) {
    .split-layout {
      flex-direction: row;
      align-items: stretch; } }

.split-layout-item {
  flex: 1; }
  @media screen and (min-width: 1024px) {
    .split-layout-item {
      padding-left: 1em; }
      .split-layout-item:first-child {
        padding: 0; } }

.split-layout-divider {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media screen and (min-width: 1024px) {
    .split-layout-divider {
      flex-direction: column; } }

.split-layout-label {
  padding: 1em; }

.split-layout-rule {
  flex: 1;
  border-style: solid;
  border-color: rgba(147, 147, 147, 0.3);
  border-width: 1px 0 0 0; }
  @media screen and (min-width: 1024px) {
    .split-layout-rule {
      border-width: 0 1px 0 0; } }

.img-sm,
.img-md,
.img-lg {
  float: left; }

.img-sm {
  width: 30px !important;
  height: 30px !important; }
  .img-sm + .img-push {
    margin-left: 40px; }

.img-md {
  width: 60px;
  height: 60px; }
  .img-md + .img-push {
    margin-left: 70px; }

.img-lg {
  width: 100px;
  height: 100px; }
  .img-lg + .img-push {
    margin-left: 110px; }

.w-1px {
  width: 1px; }

.w-full {
  width: 100%; }

.w-1\/12 {
  width: 8.333333%; }

.w-2\/12 {
  width: 16.666667%; }

.w-3\/12 {
  width: 25%; }

.w-4\/12 {
  width: 33.333333%; }

.w-5\/12 {
  width: 41.666667%; }

.w-6\/12 {
  width: 50%; }

.w-7\/12 {
  width: 58.333333%; }

.w-8\/12 {
  width: 66.666667%; }

.w-9\/12 {
  width: 75%; }

.w-10\/12 {
  width: 83.333333%; }

.w-11\/12 {
  width: 91.666667%; }

.h-0 {
  height: 0; }

.h-4 {
  height: 1rem; }

.h-20 {
  height: 20px; }

.h-full {
  height: 100%; }

.inline {
  display: inline; }

.inline-block {
  display: inline-block; }

.pad {
  padding: 10px; }

.margin {
  margin: 10px; }

.margin-top {
  margin-top: 20px; }

.margin-bottom {
  margin-bottom: 20px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.padding-left-30 {
  padding-left: 30px; }

.padding-right-10 {
  padding-right: 10px; }

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.p-0 {
  padding: 0; }

.p-1 {
  padding: 0.25rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.p-2 {
  padding: 0.5rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.p-4 {
  padding: 1rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.p-6 {
  padding: 1.5rem; }

.p-8 {
  padding: 2rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.pt-0 {
  padding-top: 0; }

.pt-1 {
  padding-top: 0.25rem; }

.pt-2 {
  padding-top: 0.5rem; }

.pt-3 {
  padding-top: 0.75rem; }

.pt-4 {
  padding-top: 1rem; }

.pt-5 {
  padding-top: 1.25rem; }

.pt-6 {
  padding-top: 1.5rem; }

.pt-7 {
  padding-top: 1.75rem; }

.pt-8 {
  padding-top: 2rem; }

.pt-9 {
  padding-top: 2.25rem; }

.pt-10 {
  padding-top: 2.5rem; }

.pt-16 {
  padding-top: 4rem; }

.pb-0 {
  padding-bottom: 0px; }

.pb-1 {
  padding-bottom: 0.25rem; }

.pb-2 {
  padding-bottom: 0.5rem; }

.pb-3 {
  padding-bottom: 0.75rem; }

.pb-4 {
  padding-bottom: 1rem; }

.pb-5 {
  padding-bottom: 1.25rem; }

.pb-6 {
  padding-bottom: 1.5rem; }

.pb-7 {
  padding-bottom: 1.75rem; }

.pb-8 {
  padding-bottom: 2rem; }

.pb-9 {
  padding-bottom: 2.25rem; }

.pb-10 {
  padding-bottom: 2.5rem; }

.w-4 {
  width: 1rem; }

.w-5 {
  width: 1.25rem; }

.w-6 {
  width: 1.5rem; }

.w-7 {
  width: 1.75rem; }

.w-8 {
  width: 2rem; }

.w-9 {
  width: 2.25rem; }

.w-10 {
  width: 2.5rem; }

.w-11 {
  width: 2.75rem; }

.w-12 {
  width: 3rem; }

.w-14 {
  width: 3.5rem; }

.w-16 {
  width: 4rem; }

.w-20 {
  width: 5rem; }

.w-24 {
  width: 6rem; }

.w-28 {
  width: 7rem; }

.w-32 {
  width: 8rem; }

.w-36 {
  width: 9rem; }

.w-48 {
  width: 12rem; }

.w-3px {
  width: 3px; }

.min-w-6 {
  min-width: 1.5rem; }

.min-w-8 {
  min-width: 2rem; }

.h-6 {
  height: 1.5rem; }

.h-8 {
  height: 2rem; }

.h-10 {
  height: 2.5rem; }

.h-16 {
  height: 4rem; }

.min-h-10 {
  min-height: 2.5rem; }

.mr-2px {
  margin-right: 2px; }

.h-21 {
  height: 5.25rem; }

.pl-0 {
  padding-left: 0px !important; }

.pl-1 {
  padding-left: 0.25rem; }

.pl-2 {
  padding-left: 0.5rem; }

.pl-3 {
  padding-left: 0.75rem; }

.pl-4 {
  padding-left: 1rem; }

.pl-5 {
  padding-left: 1.25rem; }

.pl-6 {
  padding-left: 1.5rem; }

.pl-7 {
  padding-left: 1.75rem; }

.pl-8 {
  padding-left: 2rem; }

.pl-9 {
  padding-left: 2.25rem; }

.pl-10 {
  padding-left: 2.5rem; }

.pr-0 {
  padding-right: 0px; }

.pr-1 {
  padding-right: 0.25rem; }

.pr-2 {
  padding-right: 0.5rem; }

.pr-3 {
  padding-right: 0.75rem; }

.pr-4 {
  padding-right: 1rem; }

.pr-5 {
  padding-right: 1.25rem; }

.pr-6 {
  padding-right: 1.5rem; }

.pr-7 {
  padding-right: 1.75rem; }

.pr-8 {
  padding-right: 2rem; }

.pr-9 {
  padding-right: 2.25rem; }

.pr-10 {
  padding-right: 2.5rem; }

.px-0 {
  padding-left: 0;
  padding-right: 0; }

.m-1 {
  margin: 0.25rem; }

.m-2 {
  margin: 0.5rem; }

.mt-0 {
  margin-top: 0px !important; }

.mt-1 {
  margin-top: 0.25rem; }

.mt-2 {
  margin-top: 0.5rem; }

.mt-3 {
  margin-top: 0.75rem; }

.mt-4 {
  margin-top: 1rem; }

.mt-5 {
  margin-top: 1.25rem; }

.mt-6 {
  margin-top: 1.5rem; }

.mt-7 {
  margin-top: 1.75rem; }

.mt-8 {
  margin-top: 2rem; }

.mt-9 {
  margin-top: 2.25rem; }

.mt-10 {
  margin-top: 2.5rem; }

.mt-16 {
  margin-top: 4rem; }

.mt-32 {
  margin-top: 8rem; }

.mt-36 {
  margin-top: 9rem; }

.mt-40 {
  margin-top: 10rem; }

.mt-44 {
  margin-top: 11rem; }

.mt-48 {
  margin-top: 12rem; }

.mt-52 {
  margin-top: 13rem; }

.mt-56 {
  margin-top: 14rem; }

.mt-60 {
  margin-top: 15rem; }

.mb-0 {
  margin-bottom: 0px; }

.mb-1 {
  margin-bottom: 0.25rem; }

.mb-2 {
  margin-bottom: 0.5rem; }

.mb-3 {
  margin-bottom: 0.75rem; }

.mb-4 {
  margin-bottom: 1rem; }

.mb-5 {
  margin-bottom: 1.25rem; }

.mb-6 {
  margin-bottom: 1.5rem; }

.mb-7 {
  margin-bottom: 1.75rem; }

.mb-8 {
  margin-bottom: 2rem; }

.mb-9 {
  margin-bottom: 2.25rem; }

.mb-10 {
  margin-bottom: 2.5rem; }

.mb-16 {
  margin-bottom: 4rem; }

.ml-0 {
  margin-left: 0px; }

.ml-1 {
  margin-left: 0.25rem; }

.ml-2 {
  margin-left: 0.5rem; }

.ml-3 {
  margin-left: 0.75rem; }

.ml-4 {
  margin-left: 1rem; }

.ml-5 {
  margin-left: 1.25rem; }

.ml-6 {
  margin-left: 1.5rem; }

.ml-7 {
  margin-left: 1.75rem; }

.ml-8 {
  margin-left: 2rem; }

.ml-9 {
  margin-left: 2.25rem; }

.ml-10 {
  margin-left: 2.5rem; }

.mr-0 {
  margin-right: 0px; }

.mr-1 {
  margin-right: 0.25rem; }

.mr-2 {
  margin-right: 0.5rem; }

.mr-3 {
  margin-right: 0.75rem; }

.mr-4 {
  margin-right: 1rem; }

.mr-5 {
  margin-right: 1.25rem; }

.mr-6 {
  margin-right: 1.5rem; }

.mr-7 {
  margin-right: 1.75rem; }

.mr-8 {
  margin-right: 2rem; }

.mr-9 {
  margin-right: 2.25rem; }

.mr-10 {
  margin-right: 2.5rem; }

.border-0 {
  border-width: 0px; }

.border-t-0 {
  border-top-width: 0px; }

.border-t {
  border-top-width: 1px; }

.border-l-0 {
  border-left-width: 0px; }

.border-l-3 {
  border-left-width: 3px; }

.border-l-4 {
  border-left-width: 4px; }

.border-l {
  border-left-width: 1px; }

.border-r-0 {
  border-right-width: 0px; }

.border-r {
  border-right-width: 1px; }

.border-b {
  border-bottom-width: 1px; }

.border {
  border-width: 1px; }

.border-2 {
  border-width: 2px; }

.border-b-3 {
  border-bottom-width: 3px; }

.border-4 {
  border-width: 4px; }

.border-8 {
  border-width: 8px; }

.border-solid {
  border-style: solid; }

.border-t-solid {
  border-top-style: solid; }

.border-b-solid {
  border-bottom-style: solid; }

.border-l-solid {
  border-left-style: solid; }

.border-r-solid {
  border-right-style: solid; }

.border-r-white {
  border-right-color: white; }

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)); }

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)); }

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)); }

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)); }

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)); }

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)); }

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)); }

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)); }

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)); }

.border-red-900 {
  border-width: 1px;
  border-color: #7f1d1d; }

.border-primary {
  border-color: #1c91ad; }

.focus\:outline-primary:focus {
  outline: 2px solid #1c91ad !important; }

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(28, 145, 173, var(--tw-border-opacity)); }

.border-l-primary {
  --tw-border-opacity: 1;
  border-left-color: rgba(28, 145, 173, var(--tw-border-opacity)); }

.border-l-secondary {
  border-left-color: #45a972; }

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgba(28, 145, 173, var(--tw-border-opacity)); }

.grid {
  display: grid; }

.grid-gap-px {
  grid-gap: 0; }

.grid-gap-2 {
  grid-gap: 0.5rem; }

.grid-gap-4 {
  grid-gap: 1rem; }

.grid-gap-6 {
  grid-gap: 1.5rem; }

.grid-gap-8 {
  grid-gap: 2rem; }

.grid-gap-12 {
  grid-gap: 3rem; }

.gap-1 {
  grid-gap: 0.25rem; }

.gap-2 {
  grid-gap: 0.5rem; }

.gap-4 {
  grid-gap: 1rem; }

.gap-8 {
  grid-gap: 2rem; }

.gap-12 {
  grid-gap: 3rem; }

.grid-gap-20px-10px {
  grid-gap: 20px 10px; }

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)); }

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)); }

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)); }

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)); }

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)); }

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)); }

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)); }

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)); }

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)); }

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)); }

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)); }

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)); }

.columns-1 {
  -moz-columns: 1;
       columns: 1; }

.columns-2 {
  -moz-columns: 2;
       columns: 2; }

.column-gap-12 {
  -moz-column-gap: 3rem;
       column-gap: 3rem; }

.col-span-1 {
  grid-column: span 1 / span 1; }

.col-span-2 {
  grid-column: span 2 / span 2; }

.col-span-3 {
  grid-column: span 3 / span 3; }

.col-span-4 {
  grid-column: span 4 / span 4; }

.col-span-6 {
  grid-column: span 6 / span 6; }

.col-span-7 {
  grid-column: span 7 / span 7; }

.col-span-8 {
  grid-column: span 8 / span 8; }

.col-span-9 {
  grid-column: span 9 / span 9; }

.col-span-10 {
  grid-column: span 10 / span 10; }

.col-span-11 {
  grid-column: span 11 / span 11; }

.col-span-12 {
  grid-column: span 12 / span 12; }

.row-span-2 {
  grid-row: span 2 / span 2; }

.col-start-1 {
  grid-column-start: 1; }

.col-start-2 {
  grid-column-start: 2; }

.col-start-3 {
  grid-column-start: 3; }

.col-start-4 {
  grid-column-start: 4; }

.col-start-5 {
  grid-column-start: 5; }

.col-start-6 {
  grid-column-start: 6; }

.col-start-7 {
  grid-column-start: 7; }

.col-start-8 {
  grid-column-start: 8; }

.col-start-9 {
  grid-column-start: 9; }

.col-start-10 {
  grid-column-start: 10; }

.col-start-11 {
  grid-column-start: 11; }

.col-start-12 {
  grid-column-start: 12; }

.col-end-1 {
  grid-column-end: 1; }

.col-end-2 {
  grid-column-end: 2; }

.col-end-3 {
  grid-column-end: 3; }

.col-end-4 {
  grid-column-end: 4; }

.col-end-5 {
  grid-column-end: 5; }

.col-end-6 {
  grid-column-end: 6; }

.col-end-7 {
  grid-column-end: 7; }

.col-end-8 {
  grid-column-end: 8; }

.col-end-9 {
  grid-column-end: 9; }

.col-end-10 {
  grid-column-end: 10; }

.col-end-11 {
  grid-column-end: 11; }

.col-end-12 {
  grid-column-end: 12; }

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)); }

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)); }

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)); }

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)); }

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)); }

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)); }

.place-self-center {
  place-self: center; }

.place-self-start {
  place-self: start; }

.place-self-center-start {
  place-self: center start; }

.place-self-center-end {
  place-self: center end; }

.grid-gap-px {
  grid-gap: 0px; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-1 {
  flex: 1 1 0%; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-col {
  flex-direction: column; }

.flex-col-reverse {
  flex-direction: column-reverse; }

.space-x-2 > * + * {
  margin-left: 0.5rem; }

.space-y-2 > * + * {
  margin-top: 0.5rem;
  /* 8px */ }

.space-x-4 > * + * {
  margin-left: 1rem; }

.space-y-4 > * + * {
  margin-top: 1rem;
  /* 16px */ }

.space-y-8 > * + * {
  margin-top: 2rem;
  /* 32px */ }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.justify-end {
  justify-content: flex-end; }

.justify-around {
  justify-content: space-around; }

.justify-items-center {
  justify-items: center; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.self-start {
  align-self: flex-start; }

.self-center {
  align-self: center; }

.self-end {
  align-self: flex-end; }

.self-stretch {
  align-self: stretch; }

.content-center {
  align-content: center; }

.items-stretch {
  align-items: stretch; }

.flex-grow {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.flex-wrap {
  flex-wrap: wrap; }

.order-first {
  order: -9999; }

.order-last {
  order: 9999; }

.order-none {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.separator-border {
  border-top: 1px solid #f4f4f4; }

.separator-border-r {
  border-right: 1px solid #f4f4f4; }

@media screen {
  .no-screen {
    display: none; } }

@media (min-width: 640px) {
  .sm\:pl-4 {
    padding-left: 1rem; }
  .sm\:pl-10 {
    padding-left: 2.5rem; }
  .sm\:p-4 {
    padding: 1rem; }
  .sm\:pr-4 {
    padding-right: 1rem; }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .sm\:pr-10 {
    padding-right: 2.5rem; }
  .sm\:hidden {
    display: none; }
  .sm\:block {
    display: block !important; }
  .sm\:inline {
    display: inline !important; }
  .sm\:flex {
    display: flex !important; }
  .sm\:grid {
    display: grid !important; }
  .sm\:flex-nowrap {
    flex-wrap: nowrap; }
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)); }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)); }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1; }
  .sm\:col-span-2 {
    grid-column: span 2 / span 2; }
  .sm\:col-span-3 {
    grid-column: span 3 / span 3; }
  .sm\:col-span-4 {
    grid-column: span 4 / span 4; }
  .sm\:col-start-1 {
    grid-column-start: 1; }
  .sm\:col-start-2 {
    grid-column-start: 2; }
  .sm\:col-start-3 {
    grid-column-start: 3; }
  .sm\:col-start-4 {
    grid-column-start: 4; }
  .sm\:col-end-1 {
    grid-column-end: 1; }
  .sm\:col-end-2 {
    grid-column-end: 2; }
  .sm\:col-end-3 {
    grid-column-end: 3; }
  .sm\:col-end-4 {
    grid-column-end: 4; }
  .sm\:col-end-5 {
    grid-column-end: 5; }
  .sm\:justify-start {
    justify-content: start; }
  .sm\:justify-center {
    justify-content: center; }
  .sm\:justify-between {
    justify-content: space-between; }
  .sm\:justify-end {
    justify-content: end; }
  .sm\:place-self-center {
    place-self: center; }
  .sm\:place-self-center-start {
    place-self: center start; }
  .sm\:place-self-center-end {
    place-self: center end; }
  .sm\:flex-col {
    flex-direction: column; }
  .sm\:order-none {
    order: 0; }
  .sm\:order-1 {
    order: 1; }
  .sm\:order-2 {
    order: 2; }
  .sm\:order-3 {
    order: 3; }
  .sm\:order-4 {
    order: 4; }
  .sm\:flex-row {
    flex-direction: row; }
  .sm\:gap-0 {
    gap: 0; }
  .sm\:gap-4 {
    gap: 1rem; }
  .sm\:gap-40 {
    grid-gap: 10rem; }
  .sm\:items-center {
    align-items: center; }
  .sm\:pt-0 {
    padding-top: 0; }
  .sm\:pl-2 {
    padding-left: 0.5rem; }
  .sm\:p-8 {
    padding: 2rem; }
  .sm\:pl-8 {
    padding-left: 2rem; }
  .sm\:mt-0 {
    margin-top: 0; }
  .sm\:ml-0 {
    margin-left: 0; }
  .sm\:mb-0 {
    margin-bottom: 0 !important; }
  .sm\:space-x-4 > * + * {
    margin-left: 1rem; }
  .sm\:space-y-0 > * + * {
    margin-top: 0; }
  .sm\:mt-8 {
    margin-top: 2rem; }
  .sm\:medication-intake-record-grid-template {
    grid-template-columns: 65px minmax(275px, 1fr) 1fr; }
  .sm\:medication-quantiy-grid-template {
    grid-template-columns: 70px 205px; }
  .sm\:help-card-grid-template {
    grid-template-columns: minmax(400px, 30%) 1fr; }
  .sm\:w-full {
    width: 100%; }
  .sm\:w-1\/12 {
    width: 8.333333%; }
  .sm\:w-2\/12 {
    width: 16.666667%; }
  .sm\:w-3\/12 {
    width: 25%; }
  .sm\:w-4\/12 {
    width: 33.333333%; }
  .sm\:w-5\/12 {
    width: 41.666667%; }
  .sm\:w-6\/12 {
    width: 50%; }
  .sm\:w-7\/12 {
    width: 58.333333%; }
  .sm\:w-8\/12 {
    width: 66.666667%; }
  .sm\:w-9\/12 {
    width: 75%; }
  .sm\:w-10\/12 {
    width: 83.333333%; }
  .sm\:w-11\/12 {
    width: 91.666667%; }
  .sm\:w-auto {
    width: auto; } }

@media (min-width: 1024px) {
  .md\:inline-block {
    display: inline-block; }
  .md\:block {
    display: block !important; }
  .md\:table {
    display: table !important; }
  .md\:hidden {
    display: none; }
  .md\:flex {
    display: flex !important; }
  .md\:flex-grow {
    flex-grow: 1; }
  .md\:flex-shrink {
    flex-shrink: 1; }
  .md\:flex-row {
    flex-direction: row; }
  .md\:flex-col {
    flex-direction: column; }
  .md\:flex-nowrap {
    flex-wrap: nowrap; }
  .md\:justify-start {
    justify-content: flex-start; }
  .md\:justify-center {
    justify-content: center; }
  .md\:justify-end {
    justify-content: flex-end; }
  .md\:items-center {
    align-items: center; }
  .md\:order-last {
    order: 9999; }
  .md\:space-x-4 > * + * {
    margin-left: 1rem; }
  .md\:grid {
    display: grid !important; }
  .md\:help-card-grid-template {
    grid-template-columns: minmax(300px, 30%) 1fr; }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)); }
  .md\:col-auto {
    grid-column: auto; }
  .md\:col-span-2 {
    grid-column: span 2 / span 2; }
  .md\:col-span-3 {
    grid-column: span 3 / span 3; }
  .md\:col-span-4 {
    grid-column: span 4 / span 4; }
  .md\:col-span-6 {
    grid-column: span 6 / span 6; }
  .md\:col-span-7 {
    grid-column: span 7 / span 7; }
  .md\:col-span-8 {
    grid-column: span 8 / span 8; }
  .md\:col-span-9 {
    grid-column: span 9 / span 9; }
  .md\:col-span-10 {
    grid-column: span 10 / span 10; }
  .md\:col-span-11 {
    grid-column: span 11 / span 11; }
  .md\:col-span-12 {
    grid-column: span 12 / span 12; }
  .md\:columns-1 {
    -moz-columns: 1;
         columns: 1; }
  .md\:columns-2 {
    -moz-columns: 2;
         columns: 2; }
  .md\:w-auto {
    width: auto; }
  .md\:max-w-70px {
    max-width: 70px; }
  .md\:w-1\/12 {
    width: 8.333333%; }
  .md\:w-2\/12 {
    width: 16.666667%; }
  .md\:w-3\/12 {
    width: 25%; }
  .md\:w-4\/12 {
    width: 33.333333%; }
  .md\:w-5\/12 {
    width: 41.666667%; }
  .md\:w-6\/12 {
    width: 50%; }
  .md\:w-8\/12 {
    width: 66.666667%; }
  .md\:w-9\/12 {
    width: 75%; }
  .md\:w-10\/12 {
    width: 83.333333%; }
  .md\:w-10\/12 {
    width: 83.333333%; }
  .md\:p-0 {
    padding: 0px; }
  .md\:p-4 {
    padding: 1rem; }
  .md\:pb-4 {
    padding-right: 1rem; }
  .md\:pr-4 {
    padding-right: 1rem; }
  .md\:pr-8 {
    padding-right: 2rem; }
  .md\:pl-4 {
    padding-left: 1rem; }
  .md\:mt-0 {
    margin-top: 0; }
  .md\:ml-2 {
    margin-left: 0.5rem; }
  .md\:ml-4 {
    margin-left: 1rem; }
  .md\:mb-0 {
    margin-bottom: 0 !important; }
  .md\:pl-8 {
    padding-left: 2rem; }
  .md\:separator-border-r {
    border-right: 1px solid #f4f4f4; }
  .md\:border-b {
    border-bottom-width: 1px; } }

@media (min-width: 1280px) {
  .xl\:inline-block {
    display: inline-block !important; }
  .xl\:block {
    display: block !important; }
  .xl\:hidden {
    display: none !important; }
  .xl\:table-cell {
    display: table-cell !important; }
  .xl\:grid {
    display: grid !important; }
  .xl\:help-card-grid-template {
    grid-template-columns: 500px 1fr; }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .xl\:col-auto {
    grid-column: auto; }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2; }
  .xl\:col-span-3 {
    grid-column: span 3 / span 3; }
  .xl\:flex-row {
    flex-direction: row; }
  .xl\:flex-grow {
    flex-grow: 1; }
  .xl\:flex-shrink {
    flex-shrink: 1; }
  .xl\:items-center {
    align-items: center; }
  .xl\:w-3\/12 {
    width: 25%; }
  .xl\:w-4\/12 {
    width: 33.333333%; }
  .xl\:w-6\/12 {
    width: 50%; }
  .xl\:w-7\/12 {
    width: 58.333333%; }
  .xl\:w-8\/12 {
    width: 66.666667%; }
  .xl\:w-9\/12 {
    width: 75% !important; }
  .xl\:p-4 {
    padding: 1rem; }
  .xl\:pr-4 {
    padding-right: 1rem; }
  .xl\:pl-4 {
    padding-left: 1rem; }
  .xl\:pl-8 {
    padding-left: 2rem; }
  .xl\:mt-0 {
    margin-top: 0; } }

@media (min-width: 1536px) {
  .xl2\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .xl2\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .xl2\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .xl2\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .xl2\:flex-row {
    flex-direction: row; }
  .xl2\:flex-grow {
    flex-grow: 1; }
  .xl2\:w-3\/12 {
    width: 25%; }
  .xl2\:w-5\/12 {
    width: 41.666667%; }
  .xl2\:w-6\/12 {
    width: 50%; }
  .xl2\:w-8\/12 {
    width: 66.666667%; } }

.visit-step-icon {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font: var(--fa-font-solid);
  text-align: center;
  width: 1.25em; }

.visit-step-icon-measures:before {
  content: "\f21e"; }

.visit-step-icon-treatments:before,
.visit-step-icon-PHARMA_ITW_TREATMENT:before,
.visit-step-icon-PHARMA_ITW_TREATMENT_PLAN:before,
.visit-step-icon-PHARMA_ITW_TREATMENT_PICTO:before {
  content: "\f484"; }

.visit-step-icon-PHARMA_ITW_INTERACTIONS_PICTO:before {
  content: "\f46b"; }

.visit-step-icon-PHARMA_ITW_1_PREV_PICTO:before {
  content: "\f7b9"; }

.visit-step-icon-PHARMA_ITW_MODALITES_PRISE_STEP1_PICTO:before {
  content: "\f490"; }

.visit-step-icon-EPICES:before {
  content: "\f007"; }

.visit-step-icon-EMOTIONAL_PATIENT_PROFILE:before {
  content: "\f4be"; }

.visit-step-icon-UNDERSTANDING_TREATMENT:before {
  content: "\f490"; }

.visit-step-icon-ORIENTATION:before {
  content: "\f500"; }

.visit-step-icon-ANNOUNCEMENT_CONSULTATION_SYNTHESIS:before {
  content: "\f253"; }

.visit-step-icon-HEMAVIE_TOPICS_ADDRESSED:before {
  content: "\f253"; }

.visit-step-icon-AOH_CONTROL_TEST:before {
  content: "\f76e"; }

.visit-step-icon-DATE_TREATMENT_DISPENSED:before {
  content: "\f073"; }

.visit-step-icon-AOH_SYMPTOMS:before {
  content: "\f06e"; }

.visit-step-icon-AOH_CRISIS_EVALUATION:before {
  content: "\f500"; }

.visit-step-icon-DATE_OF_NEXT_MEDICAL_CONSULTATION:before {
  content: "\f073"; }

.visit-step-icon-symptoms:before {
  content: "\f06e"; }

.visit-step-icon-biology:before {
  content: "\f492"; }

.visit-step-icon-pain:before {
  content: "\f119"; }

.visit-step-icon-adherence:before {
  content: "\f024"; }

.visit-step-icon-messaging:before {
  content: "\f086"; }

.visit-step-icon-PATIENT_PROFILE:before {
  content: "\f007"; }

.visit-step-icon-QOL_EQ_5D_5L:before {
  content: "\f005"; }

.visit-step-icon-QOL_QLQ_C30:before {
  content: "\f005"; }

.visit-step-icon-IMPORT_PRESCRIPTIONS:before {
  content: "\f572"; }

.visit-step-icon-actions:before,
.visit-step-icon-PHARMA_ITW_PPS:before {
  content: "\f0ae"; }

.visit-step-icon-preVisit:before,
.visit-step-icon-PHARMA_ITW_1_STEP1:before,
.visit-step-icon-PHARMA_ITW_1_STEP1_PICTO:before {
  content: "\f251"; }

.visit-step-icon-PHARMA_ITW_1_STEP2:before {
  content: "\f14a"; }

.visit-step-icon-PHARMA_ITW_1_STEP2_PICTO:before {
  content: "\f183"; }

.visit-step-icon-PHARMA_ITW_LIFE:before,
.visit-step-icon-PHARMA_ITW_LIFE_PICTO:before {
  content: "\f14a"; }

.visit-step-icon-PHARMA_ITW_QOL_PICTO:before {
  content: "\f005"; }

.visit-step-icon-PHARMA_ITW_EVAL:before,
.visit-step-icon-PHARMA_ITW_EVAL_PICTO:before {
  content: "\f14a"; }

.visit-step-icon-PHARMA_ITW_ADHERENCE_BIS:before {
  content: "\f14a"; }

.visit-step-icon-end:before,
.visit-step-icon-PHARMA_ITW_END_REVIEW_PICTO:before,
.visit-step-icon-PHARMA_ITW_END:before {
  content: "\f253"; }

.visit-step-icon-INTAKE_RECORD:before {
  content: "\f073"; }

.visit-step-icon-ONCOLINK_COORDINATION:before {
  content: "\f783"; }

.visit-step-icon-END_REPORT:before {
  content: "\f11e"; }

ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }

@media print {
  .no-print {
    display: none !important; }
  .breadcrumb,
  .actions * {
    display: none !important; }
  .main-sidebar,
  .left-side,
  .main-header,
  .content-header {
    display: none !important; }
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0 !important;
    min-height: 0 !important;
    transform: translate(0, 0) !important; }
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 0 !important; }
  .invoice {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0; }
  .invoice-col {
    float: left;
    width: 33.3333333%; }
  .table-responsive {
    overflow: auto; }
    .table-responsive > .table tr th,
    .table-responsive > .table tr td {
      white-space: normal !important; }
  .col-print-1 {
    width: 8%;
    float: left; }
  .col-print-2 {
    width: 16%;
    float: left; }
  .col-print-3 {
    width: 25%;
    float: left; }
  .col-print-4 {
    width: 33%;
    float: left; }
  .col-print-5 {
    width: 42%;
    float: left; }
  .col-print-6 {
    width: 50%;
    float: left; }
  .col-print-7 {
    width: 58%;
    float: left; }
  .col-print-8 {
    width: 66%;
    float: left; }
  .col-print-9 {
    width: 75%;
    float: left; }
  .col-print-10 {
    width: 83%;
    float: left; }
  .col-print-11 {
    width: 92%;
    float: left; }
  .col-print-12 {
    width: 100%;
    float: left; }
  a[href]:after {
    content: none !important; } }

.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px; }

.profile-username {
  font-size: 21px;
  margin-top: 5px; }

.ribbon {
  opacity: .5;
  border: 1px solid white;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  -moz-box-shadow: 0 0 10px #888;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  overflow: hidden;
  padding: 5px;
  position: fixed;
  top: 40px;
  transform: rotate(-45deg);
  -webkit-box-shadow: 0 0 10px #888;
  -webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon.local {
    background-color: #f39c12; }
  .ribbon.dev {
    background-color: #00c0ef; }
  .ribbon.test {
    background-color: #d9534f; }
  .ribbon.demo {
    background-color: #45a972; }
  .ribbon.preprod {
    background-color: #1c91ad; }
  .ribbon.qual {
    background-color: black; }
  .ribbon.trial {
    background-color: #605ca8; }
  .ribbon span {
    color: #fff;
    display: block;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

#splash.show,
[ng-cloak]#splash {
  display: block !important; }

#splash {
  display: none;
  text-align: center;
  margin-top: 5em; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: .4s; }

input:checked + .slider {
  background-color: #1c91ad; }

input:focus + .slider {
  box-shadow: 0 0 1px #1c91ad; }

input:checked + .slider:before {
  transform: translateX(18px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

blockquote {
  white-space: pre-wrap;
  margin-bottom: 10px;
  font-size: 1em; }

address {
  margin-bottom: 0; }

p.lead {
  margin-bottom: 10px; }

.micro-title {
  text-transform: uppercase;
  color: #777777; }

.zone-title {
  border-bottom: 1px solid #AAA;
  text-transform: uppercase;
  color: #555;
  margin: 15px 0 5px 0; }

.link-muted {
  color: #88a1c0; }
  .link-muted:hover, .link-muted:focus {
    color: #6686af; }

.link-black {
  color: #666; }
  .link-black:hover, .link-black:focus {
    color: #999; }

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem; }

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem; }

.text-base {
  font-size: 1rem;
  line-height: 1.5rem; }

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem; }

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem; }

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem; }

.text-3xl {
  font-size: 1.75rem;
  line-height: 2.6rem; }

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.75rem; }

.text-5xl {
  font-size: 3rem; }

.text-6xl {
  font-size: 3.75rem; }

.text-7xl {
  font-size: 4.5rem; }

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity)); }

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity)); }

.text-red,
.error-message,
.mandatory-question {
  color: #a94442 !important; }

.text-yellow {
  color: #f39c12 !important; }

.text-aqua {
  color: #00c0ef !important; }

.text-blue {
  color: #1c91ad !important; }

.text-black {
  color: #111 !important; }

.text-green {
  color: #45a972 !important; }

.text-gray {
  color: #ecf0f5 !important; }

.text-navy {
  color: #001F3F !important; }

.text-teal {
  color: #39CCCC !important; }

.text-olive {
  color: #3D9970 !important; }

.text-lime {
  color: #01FF70 !important; }

.text-orange {
  color: #FF851B !important; }

.text-fuchsia {
  color: #F012BE !important; }

.text-purple {
  color: #605ca8 !important; }

.text-maroon {
  color: #D81B60 !important; }

.text-gray-50 {
  color: #f9fafb; }

.text-gray-100 {
  color: #f3f4f6; }

.text-gray-200 {
  color: #e5e7eb; }

.text-gray-300 {
  color: #d1d5db; }

.text-gray-400 {
  color: #9ca3af; }

.text-gray-500 {
  color: #6b7280; }

.text-gray-600 {
  color: #4b5563; }

.text-gray-700 {
  color: #374151; }

.text-gray-800 {
  color: #1f2937; }

.text-gray-900 {
  color: #111827; }

.text-gray-950 {
  color: #030712; }

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity)); }

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity)); }

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity)); }

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity)); }

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity)); }

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity)); }

.text-green-600 {
  color: #16a34a; }

.text-primary {
  color: #1c91ad; }

.text-primary-darken {
  color: #072329; }

.italic {
  font-style: italic; }

.line-through {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through; }

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }

.font-normal {
  font-weight: 400; }

.font-semibold {
  font-weight: 600; }

.font-bold {
  font-weight: 700; }

.italic {
  font-style: italic; }

.font-size-16 {
  font-size: 16px; }

.text-danger, .text-warning, .text-success {
  font-weight: bold; }

.text-capitalize-first::first-letter {
  text-transform: uppercase; }

.postal {
  white-space: pre-wrap; }

.words-wrap {
  overflow-wrap: break-word; }

.break-words {
  overflow-wrap: break-word;
  white-space: break-spaces; }

.break {
  white-space: normal;
  word-break: break-all; }

.line-clamp-1 {
  display: -webkit-box;
  -ebkit-line-clamp: 1;
  /* autoprefixer: off */
  -Webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden; }

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -Webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden; }

@media (min-width: 640px) {
  .sm\:text-center {
    text-align: center; }
  .sm\:text-right {
    text-align: right; }
  .sm\:text-left {
    text-align: left; } }

@media (min-width: 1024px) {
  .md\:text-center {
    text-align: center; }
  .md\:text-right {
    text-align: right; }
  .md\:text-left {
    text-align: left; }
  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem; }
  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem; }
  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem; }
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem; }
  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem; }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem; }
  .md\:text-3xl {
    font-size: 1.75rem;
    line-height: 2.6rem; }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.75rem; }
  .md\:line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .md\:line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; } }

#updatebar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  left: 245px;
  top: 95px;
  text-transform: uppercase;
  z-index: 999999; }
  #updatebar a {
    font-weight: bold; }
  @media (max-width: 767px) {
    #updatebar {
      left: 15px; } }

#updatebar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s; }

@-webkit-keyframes fadein {
  from {
    top: 50px;
    opacity: 0; }
  to {
    top: 95px;
    opacity: 1; } }

@keyframes fadein {
  from {
    top: 50px;
    opacity: 0; }
  to {
    top: 95px;
    opacity: 1; } }

.skin-continuum .wrapper,
.skin-continuum .main-sidebar,
.skin-continuum .left-side {
  background-color: #1c91ad; }

.skin-continuum .main-header .navbar {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); }
  .skin-continuum .main-header .navbar .nav > li > a:hover,
  .skin-continuum .main-header .navbar .nav > li > a:active,
  .skin-continuum .main-header .navbar .nav > li > a:focus,
  .skin-continuum .main-header .navbar .nav .open > a,
  .skin-continuum .main-header .navbar .nav .open > a:hover,
  .skin-continuum .main-header .navbar .nav .open > a:focus,
  .skin-continuum .main-header .navbar .nav > .active > a {
    color: white;
    background-color: #1c91ad; }
  .skin-continuum .main-header .navbar .sidebar-toggle {
    color: #1c91ad; }
    .skin-continuum .main-header .navbar .sidebar-toggle:hover {
      color: white;
      background-color: #1c91ad; }

.skin-continuum .main-header .logo {
  background-color: #1c91ad;
  color: white; }

.skin-continuum .content-wrapper {
  background-color: #f7f7f7; }

.skin-continuum .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-continuum .sidebar-menu > li > a {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding-left: 10px; }

.skin-continuum .sidebar-menu > li.active > a {
  border-left-color: #c3d21b;
  color: white; }

.skin-continuum .sidebar-menu > li > .treeview-menu {
  margin: 0;
  padding-left: 0;
  background: #2d8da4; }

.skin-continuum .sidebar-menu > li:hover > a,
.skin-continuum .sidebar-menu > li.active > a,
.skin-continuum .sidebar-menu > li.menu-open > a {
  color: white;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); }

.skin-continuum .sidebar-menu > li:hover > a {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.skin-continuum .sidebar-menu .treeview-menu > li > a {
  color: #e7e7e7; }

.skin-continuum .sidebar-menu .treeview-menu > li.active > a,
.skin-continuum .sidebar-menu .treeview-menu > li > a:hover {
  color: white;
  border-right: 4px solid #c3d21b; }

.skin-continuum .sidebar {
  height: calc(100vh - 50px);
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */ }
  .skin-continuum .sidebar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */ }
  .skin-continuum .sidebar a {
    color: #e7e7e7; }
    .skin-continuum .sidebar a:hover {
      text-decoration: none; }

.skin-continuum.layout-top-nav .main-header > .logo {
  background-color: #1c91ad;
  color: #fff;
  border-bottom: 0 solid transparent; }
  .skin-continuum.layout-top-nav .main-header > .logo:hover {
    background-color: #23b6d9; }

@media (max-width: 767px) {
  .skin-continuum .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); } }

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0); }
  .login-page .login-box {
    padding: 20px 20px 10px 20px; }
    @media (min-width: 1024px) {
      .login-page .login-box {
        background: rgba(255, 255, 255, 0.3);
        padding: 50px 50px 20px 50px;
        border-radius: 8px; } }
  .login-page input.form-control {
    border: none; }
    .login-page input.form-control:focus {
      border: none; }
  .login-page .otp {
    background: rgba(255, 255, 255, 0.3); }
    .login-page .otp::-moz-placeholder {
      color: #1c91ad; }
    .login-page .otp:-ms-input-placeholder {
      color: #1c91ad; }
    .login-page .otp::placeholder {
      color: #1c91ad; }

.login-max-w {
  width: 100%;
  max-width: 512px; }
  @media (min-width: 1024px) {
    .login-max-w {
      width: 900px;
      max-width: 900px; } }
  @media (min-width: 1280px) {
    .login-max-w {
      width: 1024px;
      max-width: 1024px; } }

.otp {
  color: #1c91ad;
  letter-spacing: 0.3em;
  font-size: 3em;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #1c91ad;
  width: 100%;
  display: block;
  text-align: center;
  padding: 5px;
  outline: none;
  box-shadow: none;
  background: transparent; }
  .otp::-moz-placeholder {
    color: #e8e8e8; }
  .otp:-ms-input-placeholder {
    color: #e8e8e8; }
  .otp::placeholder {
    color: #e8e8e8; }

.has-error .otp {
  color: #761c19;
  border-color: #d9534f; }

.has-success .otp {
  color: #45a972;
  border-color: #45a972; }

.is-loading label {
  color: #555555; }

.is-loading .otp {
  color: #555555;
  border-color: #777777; }

@media (min-width: 992px) {
  .login-pro-sante-connect {
    border-left: 1px solid #CCC; } }

.login-pro-sante-connect a img {
  opacity: .7; }

.login-pro-sante-connect a:hover img {
  opacity: 1; }

.link-cps {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity)); }
  .link-cps:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity)); }

.dashboard {
  padding: 20px 20px; }
  @media (min-width: 1024px) {
    .dashboard {
      padding: 20px 50px 20px 50px; } }
  .dashboard .text-9 {
    font-size: 9px;
    line-height: 10.8px; }
  .dashboard .text-10 {
    font-size: 10px;
    line-height: 12px; }
  .dashboard .text-12 {
    font-size: 12px;
    line-height: 14.4px; }
  .dashboard .text-13 {
    font-size: 13px;
    line-height: 15.6px; }
  .dashboard .text-15 {
    font-size: 15px;
    line-height: 18px; }
  .dashboard .text-20 {
    font-size: 20px;
    line-height: 24px; }
  .dashboard .text-30 {
    font-size: 30px;
    line-height: 36px; }
  .dashboard .text-gray-333333 {
    color: #333333; }
  .dashboard .text-gray-797979 {
    color: #797979; }
  .dashboard .dashboardFilter {
    margin-top: 2rem;
    width: clamp(200px, 100%, 420px);
    padding: 1rem 0; }
  .dashboard .dashboard-widget-grid-template {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
    @media (min-width: 600px) {
      .dashboard .dashboard-widget-grid-template {
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); } }
  .dashboard .alert-line-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); }
    @media (min-width: 1536px) {
      .dashboard .alert-line-grid {
        grid-template-columns: repeat(auto-fill, minmax(850px, 0.663fr)); } }
  .dashboard .patient-name {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    color: #333333; }
    .dashboard .patient-name > *:last-child {
      font-size: 13px; }
  .dashboard .card {
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem 0 0 0;
    height: 400px; }
    .dashboard .card .card-title {
      padding: 0 1.5rem 1rem 1.5rem;
      font-weight: 700;
      font-size: 15px !important;
      line-height: 18.86px;
      margin: 0;
      color: #333333; }
  .dashboard .ui-scroll-viewport {
    height: 320px; }
  .dashboard .ui-scroll-viewport-with-header {
    height: 330px; }
    .dashboard .ui-scroll-viewport-with-header > div:first-child {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
      border-radius: 0; }
  .dashboard .list-item {
    border-radius: 10px;
    padding: 1rem 1.5rem 1rem 20px; }
    .dashboard .list-item[ui-scroll]:nth-last-child(n+3) {
      margin-bottom: 5px; }
    .dashboard .list-item:not([ui-scroll]):nth-last-child(n+1) {
      margin-bottom: 5px; }
    .dashboard .list-item:hover {
      background-color: #E8E8E8; }
  .dashboard button.select {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); }
  .dashboard .icon-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%; }
  .dashboard .alert-widget .card {
    height: auto; }
    .dashboard .alert-widget .card .all-alert-link {
      font-weight: 400;
      font-size: 15px;
      padding-right: 20px; }
  .dashboard .alert-widget .card-content {
    padding: 0 20px 20px 20px; }
  .dashboard .alert-widget .grade-1 {
    background-color: #fef3c7;
    color: #333333; }
  .dashboard .alert-widget .grade-2 {
    background-color: #fdba74;
    color: #333333; }
  .dashboard .alert-widget .grade-3 {
    background-color: #e53e3e;
    color: white; }
  .dashboard .alert-widget .grade-4 {
    background-color: #000000;
    color: white; }
  .dashboard .new-pharm-analysis-widget .issue-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 13px 12px 12px 13px;
    height: 26px;
    max-height: 26px;
    background-color: #FDBA74;
    box-sizing: border-box; }
    .dashboard .new-pharm-analysis-widget .issue-label .issue-icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 37px;
      height: 26px;
      max-height: 26px;
      color: #FDBA74;
      border-radius: 12px;
      background-color: white; }
    .dashboard .new-pharm-analysis-widget .issue-label .issue-count {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      color: white;
      padding: 0 7px 0 4px; }
  .dashboard .visit-todo-widget .data-grid,
  .dashboard .hdj-widget .data-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 30px;
    gap: 1rem;
    align-items: center; }
  .dashboard .visit-todo-widget .visit-schedule-style div strong,
  .dashboard .hdj-widget .visit-schedule-style div strong {
    font-weight: 400; }
  .dashboard .unbilled-home-visits-widget .ui-scroll-viewport-with-header > div:first-child {
    display: none; }
    @media (min-width: 640px) {
      .dashboard .unbilled-home-visits-widget .ui-scroll-viewport-with-header > div:first-child {
        display: grid; } }
  .dashboard .unbilled-home-visits-widget .list-item {
    padding: 0 1.5rem 0 0;
    margin-bottom: 20px !important; }
    @media (min-width: 640px) {
      .dashboard .unbilled-home-visits-widget .list-item {
        padding: 1rem 1.5rem 1rem 0;
        margin-bottom: 5px !important; } }
  .dashboard .unbilled-home-visits-widget .unbilled-home-visits-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 10px; }
    @media (min-width: 640px) {
      .dashboard .unbilled-home-visits-widget .unbilled-home-visits-item {
        display: grid;
        gap: 1rem;
        align-items: center;
        grid-template-columns: minmax(0, 1fr) minmax(75px, 1fr) 110px;
        background-color: unset; } }
    .dashboard .unbilled-home-visits-widget .unbilled-home-visits-item > div:first-child {
      font-weight: bold;
      font-size: 17px; }
      @media (min-width: 640px) {
        .dashboard .unbilled-home-visits-widget .unbilled-home-visits-item > div:first-child {
          font-weight: normal;
          font-size: 15px; } }
  .dashboard .follow-up-to-start-widget .list-item {
    margin-bottom: 20px !important; }
    @media (min-width: 640px) {
      .dashboard .follow-up-to-start-widget .list-item {
        margin-bottom: 5px !important; } }
  .dashboard .follow-up-to-start-widget .follow-up-to-start-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #f3f4f6;
    padding: 0 0 1rem 0;
    border-radius: 10px; }
    @media (min-width: 640px) {
      .dashboard .follow-up-to-start-widget .follow-up-to-start-item {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, minmax(0, 1fr)) 30px;
        background-color: unset;
        padding: 0 1.5rem 0 2.5rem;
        align-items: center; } }
    .dashboard .follow-up-to-start-widget .follow-up-to-start-item > div:nth-last-child(n+1) {
      padding: 0 1rem; }
      @media (min-width: 640px) {
        .dashboard .follow-up-to-start-widget .follow-up-to-start-item > div:nth-last-child(n+1) {
          padding: 1rem 0; } }
    .dashboard .follow-up-to-start-widget .follow-up-to-start-item > div.order-first {
      padding: 0 0; }
      @media (min-width: 640px) {
        .dashboard .follow-up-to-start-widget .follow-up-to-start-item > div.order-first {
          padding: 1rem 0; } }
    .dashboard .follow-up-to-start-widget .follow-up-to-start-item:hover {
      background-color: #E8E8E8; }
  .dashboard .follow-up-to-start-widget .follow-up-label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0 0;
    background-color: #1D6F42;
    word-break: break-all;
    padding: 2px 12px !important; }
    @media (min-width: 640px) {
      .dashboard .follow-up-to-start-widget .follow-up-label {
        border-radius: 30px 30px 30px 0; } }
  .dashboard .follow-up-to-start-widget .follow-up-label-akoadom {
    background-color: #1D91AD;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-akoapharma {
    background-color: #A041EC;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-akoapro {
    background-color: #C3D22B;
    color: #333333; }
  .dashboard .follow-up-to-start-widget .follow-up-label-suivitel {
    background-color: #2E616E;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-hemavie {
    background-color: #E4851F;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-e-suivi {
    background-color: #8B9141;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-oncolink {
    background-color: #6D6D6D;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-picto {
    background-color: #8D8D8D;
    color: white; }
  .dashboard .follow-up-to-start-widget .follow-up-label-akoatel {
    background-color: #f3a2a2;
    color: #333333; }
  .dashboard .suspended-follow-up .list-item {
    margin-bottom: 20px !important; }
    @media (min-width: 640px) {
      .dashboard .suspended-follow-up .list-item {
        margin-bottom: 5px !important; } }
  .dashboard .suspended-follow-up .suspended-follow-up-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #f3f4f6;
    padding: 1rem 0 1rem 0 !important;
    border-radius: 10px; }
    @media (min-width: 640px) {
      .dashboard .suspended-follow-up .suspended-follow-up-item {
        display: grid;
        gap: 1rem;
        grid-template-columns: minmax(0, 1fr) 30px minmax(0, 1fr) 30px;
        align-items: center;
        background-color: unset;
        padding: 0 1.5rem 0 2.5rem !important; } }
    .dashboard .suspended-follow-up .suspended-follow-up-item > div:nth-last-child(n+1) {
      padding: 0 1rem; }
      @media (min-width: 640px) {
        .dashboard .suspended-follow-up .suspended-follow-up-item > div:nth-last-child(n+1) {
          padding: 1rem 0; } }
  .dashboard .suspended-follow-up a.suspended-follow-up-item:hover {
    background-color: #E8E8E8; }
  .dashboard .suspended-follow-up .list-item {
    padding: 0 1.5rem 0 2.5rem; }
    .dashboard .suspended-follow-up .list-item .grid > div:nth-last-child(n+1) {
      padding: 1rem 0; }
  .dashboard .contract-in-progress .ui-scroll-viewport-with-header > div:first-child {
    display: none; }
    @media (min-width: 640px) {
      .dashboard .contract-in-progress .ui-scroll-viewport-with-header > div:first-child {
        display: grid; } }
  .dashboard .contract-in-progress .list-item {
    padding: 0 1.5rem 0 0;
    margin-bottom: 20px !important; }
    @media (min-width: 640px) {
      .dashboard .contract-in-progress .list-item {
        padding: 1rem 1.5rem 1rem 0;
        margin-bottom: 5px !important; } }
  .dashboard .contract-in-progress .contract-in-progress-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 10px; }
    @media (min-width: 640px) {
      .dashboard .contract-in-progress .contract-in-progress-item {
        display: grid;
        gap: 1rem;
        grid-template-columns: minmax(0, 90px) minmax(0, 1fr) minmax(0, 95px) minmax(0, 60px);
        background-color: unset; } }
    .dashboard .contract-in-progress .contract-in-progress-item > div:first-child {
      font-weight: bold;
      font-size: 17px; }
      @media (min-width: 640px) {
        .dashboard .contract-in-progress .contract-in-progress-item > div:first-child {
          font-weight: normal;
          font-size: 15px; } }

.vas {
  position: relative;
  height: 600px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  text-align: center; }

.vas__footer, .vas__header {
  font-size: 10px;
  flex-grow: 0;
  font-weight: 700;
  max-width: 150px;
  margin: 0 auto; }

.vas__body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 10px; }

.vas-tick {
  position: relative;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.vas-tick--left .vas-tick__value {
  transform: translateY(-50%) translateX(35px); }

.vas-tick--step .vas-tick__value {
  opacity: 1;
  visibility: visible; }

.vas-tick__value {
  font-size: 11px;
  position: absolute;
  top: 50%;
  opacity: 0;
  transform: translateY(-50%) translateX(35px);
  visibility: hidden; }

.vas-tick--step .vas-tick__line {
  width: 28px;
  height: 2px; }

.vas-tick__line {
  width: 12px;
  height: 1px;
  display: block;
  background: #000; }

.vas-arrow {
  position: absolute;
  width: auto;
  left: 44px;
  z-index: 99;
  transform: translateY(-50%);
  pointer-events: none;
  text-align: left;
  padding-left: 20px; }

.vas-arrow > div:first-child::before {
  content: '';
  display: block;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 18px solid #3489dc;
  position: absolute;
  left: -18px;
  top: -7px; }

.vas-arrow-value {
  display: block;
  position: absolute;
  top: -7px;
  left: 10px; }

.vas-spot {
  position: absolute;
  width: auto;
  left: 44px;
  z-index: 99;
  transform: translateY(-50%);
  pointer-events: none;
  text-align: left;
  padding-left: 20px; }

.vas-tick.is-active::before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: red;
  border-radius: 100%;
  z-index: 2;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none; }

.split-layout-help {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1280px) {
    .split-layout-help {
      flex-direction: row;
      align-items: stretch; } }

.help-screen .info-block {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.3); }
  @media (min-width: 1024px) {
    .help-screen .info-block {
      background: none;
      box-shadow: none;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
      border: none; } }

.home .home-button {
  margin-bottom: 30px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); }
  .home .home-button:hover {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(28, 145, 173, 0.6); }
    .home .home-button:hover > a {
      color: #23b6d9; }
  .home .home-button a {
    display: block;
    text-align: center;
    background: white;
    padding: 25px 10px;
    border-radius: 8px; }
    .home .home-button a span.icon {
      display: block;
      font-size: 3em; }
    .home .home-button a span.name {
      display: block;
      font-size: 1.3em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.identite-nationale-de-sante-grid-template {
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr); }

.identite-nationale-de-sante-grid-template-line {
  grid-template-columns: 60px 1fr; }

.measure-score-1 {
  border-left: 3px solid #fef3c7; }

.measure-score-2 {
  border-left: 3px solid #fdba74; }

.measure-score-3 {
  border-left: 3px solid #e53e3e; }

.measure-score-4 {
  border-left: 3px solid #000000; }

.messaging {
  padding: 0; }
  @media (min-width: 992px) {
    .messaging .col-patient-list {
      padding-right: 0; } }
  .messaging .col-patient-list .patient-list {
    height: calc(100vh - 150px);
    overflow-y: auto; }
  @media (min-width: 992px) {
    .messaging.messaging-practitioner .col-messages {
      padding-left: 0; } }
  .messaging .col-messages .patient-name {
    padding: 4px 12px;
    line-height: 1.42857; }
  .messaging .messages {
    height: 300px;
    overflow-y: auto; }
    .messaging .messages .message {
      clear: both;
      margin: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dotted lightgray; }
      .messaging .messages .message:last-child {
        border: none;
        padding: 0; }
      .messaging .messages .message.important blockquote {
        border-color: #a94442; }
      .messaging .messages .message .info {
        margin-bottom: 5px; }
        .messaging .messages .message .info .author {
          margin-right: 5px;
          font-weight: bold;
          color: #333333; }
        .messaging .messages .message .info time {
          color: #777777;
          font-size: .8em; }
  .messaging .patient-disclaimer-block {
    height: 110px; }
  .messaging textarea {
    padding: 1rem; }
    .messaging textarea::-moz-placeholder {
      color: #777777; }
    .messaging textarea:-ms-input-placeholder {
      color: #777777; }
    .messaging textarea::placeholder {
      color: #777777; }
  .messaging .important-block {
    border-top: 1px solid #eeeeee;
    height: 30px; }
    .messaging .important-block label {
      float: left;
      display: block;
      font-weight: inherit;
      padding: 3px;
      margin: 0;
      text-align: center;
      width: 50%;
      height: 30px; }
  .messaging .send-block {
    width: 20%;
    border-top: 1px solid #eeeeee; }
    .messaging .send-block button {
      float: left;
      border: none !important;
      display: block;
      width: 100%;
      height: 110px; }

.mailbox-message {
  padding: 10px;
  border-top: 1px solid #EEEEEE;
  border-left: 3px solid #eee; }
  .mailbox-message:focus {
    outline: none !important; }
  .mailbox-message.important {
    border-left-color: #a94442; }

.message-unread {
  font-weight: bold; }

.message-full .body {
  white-space: pre-wrap; }

.message-important {
  border-left: 3px solid #a94442; }

.message-content {
  clear: left; }

.message.important blockquote {
  border-color: #a94442; }

.recipients td {
  padding: 3px; }

@media (max-width: 1280px) {
  .patient-nav-tabs li a span {
    display: none; } }

.patient-nav-tabs li a button {
  border: none;
  padding: 0;
  color: #555555;
  font-size: .7em; }

.patient-nav-tabs li.active a,
.patient-nav-tabs li.active a:hover,
.patient-nav-tabs li.active a:focus {
  background: #f7f7f7; }

.patient-nav-tabs li.disabled {
  cursor: not-allowed; }
  .patient-nav-tabs li.disabled a {
    pointer-events: none;
    cursor: not-allowed; }

patient-team-panel .team-grid {
  grid-template-columns: 1fr; }

@media (min-width: 1024px) {
  patient-team-panel .team-grid {
    grid-template-columns: 1fr 2fr 1fr; } }

.planning td, .planning th {
  vertical-align: middle; }

.planning .btn.btn-filter {
  background-color: white;
  border: 1px solid #1c91ad;
  color: #1c91ad !important; }
  .planning .btn.btn-filter:hover {
    background-color: #e5e7eb; }

.planning .label {
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  font-size: 11px; }

.planning .day-block-width {
  width: 100px; }
  @media (min-width: 1024px) {
    .planning .day-block-width {
      width: 85px; } }
  @media (min-width: 1280px) {
    .planning .day-block-width {
      width: 100px; } }

@media screen {
  .hidden-screen {
    display: none; } }

.prescription blockquote {
  border-top: none;
  border-right: none;
  border-bottom: none; }

.prescription .panel {
  box-shadow: none; }
  .prescription .panel .panel-heading {
    border-bottom: 1px solid #555555; }
    .prescription .panel .panel-heading .panel-title {
      text-align: center; }

@media print {
  .prescription .panel {
    height: 220px; } }

ul.prescription-items {
  list-style-type: none; }
  ul.prescription-items li {
    margin-bottom: 10px; }
    ul.prescription-items li .item {
      font-size: 1.1em; }

side-effect-report-score {
  outline: none !important; }

.label-side-effect-report-score {
  cursor: pointer; }
  .label-side-effect-report-score.bg-gray {
    opacity: .5;
    cursor: inherit; }

.score-0 {
  border-color: #eeeeee !important; }

.score-1 {
  border-color: #fef3c7 !important; }

.score-2 {
  border-color: #fdba74 !important; }

.score-3 {
  border-color: #e53e3e !important; }

.score-4 {
  border-color: #000000 !important; }

.coordination-box-S11_VALIDATED {
  border-left: 3px solid #1c91ad; }

.coordination-box-S20_ACTIVE {
  border-left: 3px solid #00c0ef; }

.coordination-box-S30_PAUSED {
  border-left: 3px solid #f39c12; }

.coordination-box-S40_FINISHED {
  border-left: 3px solid #45a972; }

.coordination-box-S50_STOPPED {
  border-left: 3px solid #d9534f; }

.coordination-box-S99_CANCELED {
  border-left: 3px solid #333333; }

.label-patient-therapy-status-S00_CREATED {
  background-color: #777777; }

.label-patient-therapy-status-S11_VALIDATED {
  background-color: #1c91ad; }

.label-patient-therapy-status-S15_STARTED {
  background-color: #1c91ad; }

.label-patient-therapy-status-S20_ACTIVE {
  background-color: #00c0ef; }

.label-patient-therapy-status-S30_PAUSED {
  background-color: #f39c12; }

.label-patient-therapy-status-S35_ACTIVE_PAUSED {
  background-color: darkred; }

.label-patient-therapy-status-S40_FINISHED {
  background-color: #45a972; }

.label-patient-therapy-status-S50_STOPPED {
  background-color: #d9534f; }

.label-patient-therapy-status-S99_CANCELED {
  background-color: #333333; }

.label-patient-treatment-status-S10_PENDING {
  background-color: #777777; }

.label-patient-treatment-status-S40_FINISHED {
  background-color: #45a972; }

.label-patient-treatment-status-S50_STOPPED {
  background-color: #d9534f; }

.label-patient-treatment-status-S99_CANCELED {
  background-color: #333333; }

.label-treatment-status-S10_PENDING {
  background-color: #777777; }

.label-treatment-status-S40_FINISHED {
  background-color: #45a972; }

.label-treatment-status-S50_STOPPED {
  background-color: #d9534f; }

.label-treatment-status-S99_CANCELED {
  background-color: #333333; }

.label-patient-status-TO_CONTACT {
  background-color: #1c91ad; }

.label-patient-status-CONTACTED {
  background-color: #00c0ef; }

.label-patient-status-COMPLETED {
  background-color: #45a972; }

.label-action-status-S40_DONE {
  background-color: #45a972; }

.label-default {
  color: black; }

.table-side-effect-report-matrix td {
  outline: none !important;
  position: relative;
  vertical-align: middle !important; }
  .table-side-effect-report-matrix td.score {
    width: 50px;
    text-align: center;
    opacity: .5; }
    .table-side-effect-report-matrix td.score:hover {
      opacity: 1 !important; }
  .table-side-effect-report-matrix td.recent {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-size: 1.3em;
    opacity: 1 !important; }
  .table-side-effect-report-matrix td .fa-comment {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 10px; }
  .table-side-effect-report-matrix td .fa-camera {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 10px; }

.table-side-effect-report-matrix th.recent {
  background: #eeeeee;
  border-bottom: 3px solid black; }

.table-plan-detail-edit {
  border-collapse: collapse; }
  .table-plan-detail-edit th {
    border: 1px solid #EEE !important; }
  .table-plan-detail-edit td {
    padding: 0 !important;
    border: 1px solid #AAA !important; }
    .table-plan-detail-edit td input {
      display: block;
      border: none;
      text-align: center;
      font-weight: bold;
      width: 100%;
      padding: 3px 0;
      background: lightblue; }
      .table-plan-detail-edit td input:focus {
        outline: none;
        background: #ddeafc; }
      .table-plan-detail-edit td input::-moz-placeholder {
        color: #DDD; }
      .table-plan-detail-edit td input:-ms-input-placeholder {
        color: #DDD; }
      .table-plan-detail-edit td input::placeholder {
        color: #DDD; }
      .table-plan-detail-edit td input:-moz-placeholder-shown {
        background: white; }
      .table-plan-detail-edit td input:-ms-input-placeholder {
        background: white; }
      .table-plan-detail-edit td input:placeholder-shown {
        background: white; }

.table-plan-detail-monthly-edit {
  border-collapse: collapse; }

.table-plan-detail > thead > tr > th {
  color: #333333; }

.table-plan-detail.disabled {
  opacity: .5; }

.table-plan-detail td {
  vertical-align: middle !important; }

.therapy-detail table td, .therapy-detail table th {
  border-bottom: 1px solid lightgrey; }

.therapy-detail table th {
  border-right: 2px solid lightgrey; }

.therapy-detail table td {
  border-right: 1px dashed lightgray; }

.visit {
  background: #fff;
  border: none;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 25px;
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); }
  .visit .patient {
    margin-top: 0;
    margin-bottom: 10px; }
  .visit .visit-status {
    top: -15px;
    right: -5px;
    position: absolute;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: normal;
    white-space: nowrap; }
  .visit .action {
    bottom: 15px;
    right: 15px;
    position: absolute; }

.visit-detail .editable-name {
  padding: 4px; }
  .visit-detail .editable-name input {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 4px;
    font-size: 18px;
    font-weight: bold; }
    .visit-detail .editable-name input[readonly] {
      background-color: transparent; }
  .visit-detail .editable-name.editing input {
    border: 2px solid #1c91ad; }
  .visit-detail .editable-name:not(.editing) input:hover {
    background-color: #eeeeee !important; }

.visit-wizard .question {
  font-size: 1.15em; }

.visit-wizard .pain-value {
  font-size: 2em; }

.visit-wizard .actions .btn {
  border: 0; }

.visit-wizard .visit-title {
  overflow: hidden;
  padding: 10px 0;
  line-height: 1.33333;
  font-size: 18px; }

.visit-wizard .patient {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-all;
  padding: 6px 12px 6px 0;
  vertical-align: middle;
  font-size: 16px; }

.visit-wizard .btn-visit-step {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  text-align: left; }
  .visit-wizard .btn-visit-step i {
    line-height: inherit; }

.visit-wizard .measures-form .form-group.has-error .control-label {
  color: #a94442; }

.visit-wizard .measures-form .form-group.has-error .input-measure {
  color: #a94442; }

.visit-wizard .measures-form .measure-input-block {
  background: #fff;
  padding: 10px 0;
  border: 1px solid #eeeeee;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }
  .visit-wizard .measures-form .measure-input-block label {
    font-weight: normal;
    text-transform: uppercase;
    font-size: .9em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem; }
  .visit-wizard .measures-form .measure-input-block .input-measure {
    font-size: 28px;
    font-weight: bold;
    height: 50px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    border-radius: 0; }
    .visit-wizard .measures-form .measure-input-block .input-measure.ng-invalid-required {
      border-bottom-color: #ebccd1;
      background-color: #f2dede; }
    .visit-wizard .measures-form .measure-input-block .input-measure.input-measure-value2 {
      border-left: 1px solid #ccc; }
    .visit-wizard .measures-form .measure-input-block .input-measure:focus {
      outline: none; }
    .visit-wizard .measures-form .measure-input-block .input-measure::-moz-placeholder {
      color: #ccc;
      font-weight: 200; }
    .visit-wizard .measures-form .measure-input-block .input-measure:-ms-input-placeholder {
      color: #ccc;
      font-weight: 200; }
    .visit-wizard .measures-form .measure-input-block .input-measure::placeholder {
      color: #ccc;
      font-weight: 200; }
    .visit-wizard .measures-form .measure-input-block .input-measure:-ms-input-placeholder {
      color: #ccc;
      font-weight: 200; }
  .visit-wizard .measures-form .measure-input-block .unit {
    margin-top: 5px;
    color: #555555; }

.visit-wizard .btn-visit-side-effect {
  padding: 10px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  border: none;
  border-bottom: 1px solid #eeeeee; }
  .visit-wizard .btn-visit-side-effect .name {
    font-size: 1.2em; }
  .visit-wizard .btn-visit-side-effect .description {
    white-space: normal; }
  .visit-wizard .btn-visit-side-effect .comment {
    white-space: normal; }

.visit-wizard .side-effect-group {
  color: #333333;
  font-size: .9em;
  text-transform: uppercase;
  border-bottom: 1px solid #AAA;
  margin-top: 10px;
  margin-bottom: 10px; }

.visit-wizard .side-effect-score-wrapper {
  position: relative; }
  .visit-wizard .side-effect-score-wrapper .side-effect-score-selection-block {
    margin-bottom: 15px;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center; }
    .visit-wizard .side-effect-score-wrapper .side-effect-score-selection-block:focus, .visit-wizard .side-effect-score-wrapper .side-effect-score-selection-block:active {
      outline: none; }
    .visit-wizard .side-effect-score-wrapper .side-effect-score-selection-block side-effect-report-score {
      top: -10px;
      right: -5px;
      position: absolute;
      font-size: 2em; }
    .visit-wizard .side-effect-score-wrapper .side-effect-score-selection-block .selected-score {
      bottom: 0;
      right: -5px;
      position: absolute;
      font-size: 2.5em; }

.visit-wizard .messaging .messages {
  height: auto; }

.visit-wizard .messaging .write-block .body textarea {
  height: auto; }

.visit-wizard .messaging .important-block label {
  margin-top: 10px;
  margin-bottom: 10px; }

.visit-wizard .messaging .focus\:outline-none:focus {
  outline: none; }

/* Intake Record */
visit-intake-record .form-control.input-value {
  padding-left: 6px;
  padding-right: 6px;
  border-right-width: 0;
  text-align: right;
  width: 42px; }

/* Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
----------------------------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width
--------------------------------------------------------------------------------*/
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right
----------------------------------------------------------------------*/
.flex-btn-group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

/* glyphicon-sort
-----------------*/
.glyphicon-sort {
  /* less visible sorting icons */
  opacity: 0.5; }
  .glyphicon-sort:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }
  .glyphicon-sort .glyphicon-sort-by-attributes, .glyphicon-sort .glyphicon-sort-by-attributes-alt {
    opacity: 1; }

/* _fix for sparkline tooltip */
.jqstooltip {
  box-sizing: content-box;
  padding: 5px !important;
  width: auto !important;
  height: auto !important; }

/* jh-entity-details
--------------------*/
.dl-horizontal.jh-entity-details > dt {
  font-weight: normal; }

.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    padding-left: 180px;
    margin-left: 0; } }

/* Miscellaneous
----------------*/
.notification .title1 {
  color: #1c91ad;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px; }

.notification .title2 {
  font-size: 14px;
  font-weight: 700; }

.text-dispensed {
  color: #5cb85c; }

.text-not-dispensed {
  color: #E53E3E; }

.bg-dispensed {
  background-color: #5cb85c; }

.bg-not-dispensed {
  background-color: #E53E3E; }

.label:not(.score-1, .score-2, .score-3, .score-4, .label-side-effect-report-score) {
  display: inline-block;
  border-radius: 4px;
  padding: 8px 8px; }
